<template>
  <div id="travel-inspired-page" v-if="stories && stories.length > 0">
    <div class="carousel-container">
      <h2 class="carousel-title">Get Travel Inspired</h2>
      <carousel
        :items="1"
        :margin="77"
        :stagePadding="30"
        :center="true"
        :nav="false"
        :dots="false"
        :loop="true"
        :autoWidth="true"
        class="stories-list"
        @updated="onCarouselUpdate()"
        ref="carousel"
      >
        <div
          v-for="story in stories"
          :key="story.id"
          class="post-item"
          :style="{ backgroundImage: `url(${story.thumbnailUrl})` }"
          @click="() => onClickStory(story.id)"
        >
          <div class="post-title">{{ story.title }}</div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import carousel from "vue-owl-carousel";
import StoryPopupManager from "@/services/StoryPopupManager";

export default {
  name: "TravelInspiredPage",
  components: {
    carousel,
  },
  props: {
    travelerTypes: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stories: [],
      final: true,
    };
  },
  methods: {
    load() {
      const url = process.env.VUE_APP_API_URI;

      const postData = {
        query: `query stories($limit: Int, $travelerTypes: [ID], $locations: [ID]) { stories(limit: $limit, travelerTypes: $travelerTypes, locations: $locations) { final data { id title name thumbnailUrl }} }`,
        variables: {
          limit: 100,
          travelerTypes: this.travelerTypes,
          locations: this.locations,
        },
      };
      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          const stories = res.data.data.stories;
          this.stories = stories.data;
          this.final = stories.final;
        })
        .catch(console.error);
    },
    onClickStory(id) {
      StoryPopupManager.open(id);
    },
  },
  mounted() {
    this.load();
  },
  updated() {
    //console.log(this.$refs.carousel);
    // this.$refs.carousel.on("mousewheel", ".owl-stage", ev => {
    //   if (e.deltaY > 0) {
    //     owl.trigger("next.owl");
    //   } else {
    //     owl.trigger("prev.owl");
    //   }
    //   e.preventDefault();
    // });
  },
};
</script>

<style lang="scss" scoped>
#travel-inspired-page {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease background-color;

  .carousel-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    overflow: hidden;
    width: 100%;

    h2 {
      font-size: 4.16vw;
      font-weight: bold;
      color: #e13036;
      text-align: center;
      margin: 0 auto 0.5em;
    }

    .post-item {
      display: inline-block;
      height: 0;
      width: 46.66vw;
      height: 460px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;

      .post-title {
        color: #fff;
        font-size: 2.4vw;
        font-weight: bold;
        text-align: center;
        position: absolute;
        bottom: 8.26%;
        width: 100%;
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
</style>
