<template>
    <div id="themesHead" class="themesHead">
        <b-navbar class="themesHead-bg" :style="slideIndex"  variant="faded" type="light" toggleable="lg" fixed="top">
            <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
            <b-navbar-brand to="/">
                <img :src="logoHome" alt="Mongolia">
            </b-navbar-brand>
        </b-navbar>
    </div>
</template>

<script>
// import { TweenLite } from "gsap/all";
import LogoHome from '../assets/img/logo-mongolia-home.png';
import ThemesFestivelnaadam from '../assets/img/themes/FolkDance.png';
import travel04 from '../assets/img/travel04.jpg'

export default {
    name : 'ThemesHead',
    props: {
        slideIndex : {
            type: Object,
            default : {
                'background-image':'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,0))'
            }
        }
    },
    data() {
        return {
            logoHome : LogoHome,
            ThemesFestivelnaadam : ThemesFestivelnaadam,
            travel04 : travel04
        } 
    }
}

</script>


<style lang="scss" scoped>
.themesHead {
    .themesHead-bg {
        background-image: linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,0));
    }
    .fixed-top {
        z-index: 10;
    }
}



.themesHead {
    background-image: linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,0));
    .mc {
        max-width: 75%;
        height: auto;
        width: auto;
    }
}
</style>
