<template>
  <div class="themes" v-if="path">
    <ThemesHead :slideIndex="slideIndex"/>
    <div class="themes-box">
      <div class="box-left">
        <TransitionContainer @slideIndexSelect="slideIndexSelectThemes">
          <TransitionSlide>
            <div class="img-bg" :style="{backgroundImage : 'url('+background+')'}">
              <div class="topic-name">{{text}}</div>
            </div>
            <div class="description">
              <div id="topic">{{topics}}</div>
              <div class="text" id="text" v-html="des"></div>
            </div>
          </TransitionSlide>
          <TransitionSlide>
            <InTheSpotlights :travelerTypes="[path]"/>
          </TransitionSlide>
          <TransitionSlide>
            <TravelInspired :travelerTypes="[path]"/>
          </TransitionSlide>
          <TransitionSlide>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <ContentsBoard :travelerTypes="[path]"/>
          </TransitionSlide>
        </TransitionContainer>
      </div>
      <div class="box-right">
        <div class="travel">
          <div class="title" v-bind:style="bgc">
            <p data-aos="zoom-out" data-aos-delay="300">TRAVEL THEMES</p>
            <h3>{{text}}</h3>
          </div>
          <div class="gallery">
            <ImagesBoard :perPage="8" :grid="true" :columns="2" :tag="path"/>
          </div>
        </div>
      </div>
    </div>
    <NavrightHome/>
  </div>
</template>

<script>
import("aos").then(AOS => AOS.init());
import ThemesHead from "@/components/ThemesHead";
import NavrightHome from "@/components/NavrightHome";
import ImagesBoard from "@/components/ImagesBoard";
import InTheSpotlights from "@/components/InTheSpotlights";
import TravelInspired from "@/components/TravelInspired";
import TransitionContainer from "@/components/TransitionContainer";
import TransitionSlide from "@/components/TransitionSlide";
// import Discover from "@/components/Discover";
import ContentsBoard from "@/components/ContentsBoard";
import travel04 from "../assets/img/logo-mongolia-home-2.png";
import themes01 from "../assets/img/themes/FolkDance.jpg";
import themes02 from "../assets/img/themes/lightscape.jpg";
import themes03 from "../assets/img/themes/shutterstock_10.jpg";
import themes04 from "../assets/img/themes/ReligionRituals.jpg";
import themes05 from "../assets/img/themes/ManofMillenium.jpg";
import themes06 from "../assets/img/themes/shutterstock.jpg";
import themes07 from "../assets/img/themes/shutterstock_1.jpg";
import themes08 from "../assets/img/themes/shutterstock_6.jpg";
import themes09 from "../assets/img/themes/Budget-Category.jpg";
import themes10 from "../assets/img/themes/Luxury-Category.jpg";

export default {
  name: "themes",
  data() {
    return {
      travel04: travel04,
      path: "",
      background: "",
      text: "",
      topics: "",
      bgc: "",
      des: "",
      slideIndex: false,
      themes: {
        festivals: {
          background: themes01,
          text: "FESTIVALS",
          topics: "The festivals of Mongolia",
          bgc: {
            backgroundColor: "#E13036"
          },
          des:
            "<p>Planning a trip to Mongolia soon? Why not plan it along one of many exciting festivals and events that occur throughout the country? Festivals are a huge part of our culture and traditions passed down through our ancestors.</p>" +
            "<p>Revolving around food festivities, ceremonial customs, horse and camel races, wrestling, archery and even animal hunting, there is a festival for all to experience.</p>" +
            "<p>Do not miss iconic events such as the Khovsgol Ice Festival, Naadam Festival, Gobi Camel Festival and the Eagle Festival.</p>"
        },
        nature: {
          background: themes02,
          text: "NATURE",
          topics: "Nature",
          bgc: {
            backgroundColor: "#D57504"
          },
          des:
            "<p>Imagine being immersed in a vast land steeped in centuries-old nomadic lifestyle as you journey through rocky mountain ranges, rolling grassy hills and awe-inspiring sand dunes of the Gobi Desert. Gorgeous lakes, nature reserves and national parks make up other parts of the natural wonders of the country.</p>" +
            "<p>The beautiful and varied landscapes of Mongolia will attract all kinds of travelers from photographers to adventure seekers.</p>"
        },
        "family-activities": {
          background: themes03,
          text: "FAMILY ACTIVITIES",
          topics: "Mongolia for all ages",
          bgc: {
            backgroundColor: "#34679F"
          },
          des:
            "<p>With an open mind and a knack for adventure, Mongolia is a destination built just for families with kids. Almost everything that can be done is family-friendly and safe for children. Highlight activities include staying in a Ger, going horseback riding, visiting natural museums, hiking up sand dunes and even (attempt) hunting for dinosaur eggs in the canyons.</p>" +
            "<p>There is literally nothing like being in out in the wide-open spaces filled with natural beauties and experiencing the slow life. If that is an ideal vacation for your family, then look no further than Mongolia.</p>"
        },
        "buddhist-tourism": {
          background: themes04,
          text: "BUDDHIST",
          topics: "Buddhism Mongolia",
          bgc: {
            backgroundColor: "#10523B"
          },
          des: ""
        },
        history: {
          background: themes05,
          text: "HISTORY & CULTURE",
          topics: "History & Culture",
          bgc: {
            backgroundColor: "#10523B"
          },
          des:
            "<p>Since the birth of Genghis Khan, the great Mongol Empire made an iconic mark in the world. Among the conquests and falls of the empire, a new modern Mongolia was created one that is democratic and independent.</p>" +
            "<p>Cver the centuries the country has kept its traditions and continues a nomadic lifestyle at its core values. All customs, traditions, music, and fine arts are influenced by it.</p>"
        },
        "bird-watching": {
          background: themes06,
          text: "BIRD WATCHING",
          topics: "Bird Watching",
          bgc: {
            backgroundColor: "#228B5B"
          },
          des:
            "<p>Home to over 400 species of migratory and regional birds, Mongolia is one of the best go-to destinations for bird watching.</p>" +
            "<p>With so much natural beauty and diverse landscapes, bird enthusiasts and photographers able to find exotic birds in different regions of the country. Whether it’s the rolling steppes and grasslands, Gobi Desert or the mountains, there’ always an interesting bird to catch your eye.</p>" +
            "<p>Popular places for bird watching are Ugii Lake, Orkhon waterfall and Khogno khan National park.</p>" +
            "<h5 style=" +
            "color:blue;" +
            ">Interesting fact: Saker Falcon is the national bird of Mongolia.</h5>"
        },
        "community-based": {
          background: themes07,
          text: "COMMUNITY BASED TOURISM",
          topics: "Community Based Tourism",
          bgc: {
            backgroundColor: "#911457"
          },
          des:
            "<p>These days more and more travelers are seeking authentic experiences. Nothing speaks more authentic than those tours and experiences created by the locals or the communities that they reside in. Mongolia sees the potential in this. </p>" +
            "<p>With our history and culture imbued in a nomad lifestyle, we are focusing more on tourist attractions that reflect on that. Increasing amount of herder families also realize this and of fer activities and experiences that focus on their core value.</p>" +
            "<p>From guided treks and herding activities to living with nomadic families to even building local products, the opportunities are ever growing. Community based tourism not only enriches the experiences of travelers but also gives back to the communities, empowering them to sustain their livelihoods.</p>" +
            "<p>There are over a couple of dozen tourism-based communities all around Mongolia that travelers can interact with. Some near to town while some extremely far. We bring you that much closer to them.</p>"
        },
        taste: {
          background: themes08,
          text: "TASTE OF MONGOLIA",
          topics: "Taste of Mongolia",
          bgc: {
            backgroundColor: "#5F120F"
          },
          des:
            "<p>Mongolia is all about meat and dairy, especially of goat. Goat milk and mutton are a staple part of their everyday diet.</p>" +
            "<p>Buuz, a popular dish is a Mongolian dumpling stuffed with meat and vegetables. So is khorkhog, a barbecue of meat and vegetables cooked with hot stones and water.</p>" +
            "<p>If you visit a nomadic family anywhere in the country, these are just some of the dishes you can taste and enjoy. Tea with goat milk and salt is also commonly served 1-3 times a day in every household.</p>"
        },
        adventure: {
          background: themes09,
          text: "ADVENTURE",
          topics: "Adventure",
          bgc: {
            backgroundColor: "#340A4E"
          },
          des:
            "<p>Imagine a land where Genghis Khan once roamed with his Mongol empire at his side on horsebacks. Exploring, discovering and conquering new lands, it is sure they had much adventure. What if you could experience even the tiniest glimpse of it? </p>" +
            "<p>Now known for its nomadic way of life and untouched, majestic landscapes, there is plenty of adventure to be had in modern Mongolia.</p>" +
            "<p>Horse and camel riding, trekking, hiking, kayaking and mountain biking are just some of adventure activities travelers can experience in even the remotest part of the country.</p>" +
            "<p>If these do not suit your style, there are still all kinds of soft adventures waiting at every corner as you visit and live with the locals.</p>"
        },
        mice: {
          background: themes10,
          text: "MICE",
          topics: "Meeting Incentive Conference Exhibition",
          bgc: {
            backgroundColor: "#222F6A"
          },
          des:
            "<p>Mongolia is an emerging market for the MICE industry with an increasing amount of tour operators and businesses arranging meeting halls, conference and exhibition venues that are fully equipped with modern technologies.</p>" +
            "<p>With its stable politics, growing economics, rich culture, and beautiful landscapes, Mongolia is quickly becoming a unique and alluring destination for travel conferences and events.</p>" +
            "<p>One of the top features that MICE clients look forward to in Mongolia are the creative theme events, breakaway tours and outdoor team building activities far beyond the walls of a meeting room.</p>"
        }
      }
    };
  },
  components: {
    TransitionContainer,
    TransitionSlide,
    ThemesHead,
    NavrightHome,
    ImagesBoard,
    InTheSpotlights,
    ContentsBoard,
    TravelInspired
  },
  mounted() {
    let path = this.$route.params.nametravel;
    // console.log(path);
    this.path = path;
    this.background = this.themes[path].background;
    this.text = this.themes[path].text;
    this.topics = this.themes[path].topics;
    this.bgc = this.themes[path].bgc;
    this.des = this.themes[path].des;
  },
  methods: {
    slideIndexSelectThemes(value) {
      var vm = this;
      var values = value
      setTimeout(function(){
        if (values > 0) {
          vm.slideIndex = {'background-color': vm.bgc.backgroundColor, 'background-image':'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0))'};
        } else {
          vm.slideIndex = {'background-image':'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,0))'};
        }
      },500) 
      
    }
  }
};
</script>

<style lang="scss" scoped>
.themes {
  .themes-box {
    display: flex;

    .box-left {
      width: 70%;

      .img-bg {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 74vh;
        position: relative;

        .topic-name {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 4.16vw;
          font-weight: bold;
          text-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
          user-select: none;
          text-transform: capitalize;
          white-space: nowrap;
        }
      }

      .description {
        position: relative;
        bottom: 0;
        display: flex;
        max-width: 100%;
        margin-bottom: 50px;
        #topic {
          max-width: 80%;
        }

        #text {
          max-width: 75%;
        }
      }
    }

    .box-right {
      position: fixed;
      right: calc(0% + 40px);
      z-index: 10;
      width: calc(30% - 40px);
      height: 100vh;
      .travel {
        width: 100%;
        height: 100%;

        .title {
          text-align: center;
          color: white;
          height: 102px;
        }

        .gallery {
          height: calc(100vh - 102px);
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .list-img {
            position: relative;
            height: 25%;
            width: 50%;
            border-style: solid;
            border-color: white;
            background-color: gray;

            .image {
              position: absolute;
              bottom: 0;
              height: 20%;
              width: 100%;
              background-image: linear-gradient(
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.6)
              );

              .icon {
                position: absolute;
                background-image: url("../assets/img/themes/Group 87.png");
                background-repeat: no-repeat;
                bottom: 30%;
                margin-left: 5%;
                width: 10%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .themes {
    .themes-box {
      .box-left {
        .description {
          #topic {
            padding: 40px 8.43vw 0;
            font-size: 1.82vw;
            line-height: 1.35em;
          }

          #text {
            padding: 3.28vw 6.97vw 30px 0;
            font-size: smaller;
          }
        }
      }

      .box-right {
        z-index: 10;
        width: calc(30% - 40px);
        height: 100vh;
        .travel {
          width: 100%;
          height: 100%;

          .title {
            text-align: center;
            color: white;
            height: 102px;
          }

          .gallery {
            height: calc(100vh - 102px);
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .list-img {
              position: relative;
              height: 25%;
              width: 50%;
              border-style: solid;
              border-color: white;
              background-color: gray;
              //list-img is frame of images

              .image {
                position: absolute;
                bottom: 0;
                height: 20%;
                width: 100%;
                background-image: linear-gradient(
                  rgba(0, 0, 0, 0),
                  rgba(0, 0, 0, 0.6)
                );
                //image is shadow on images

                .icon {
                  position: absolute;
                  background-image: url("../assets/img/themes/Group 87.png");
                  background-repeat: no-repeat;
                  bottom: 30%;
                  margin-left: 5%;
                  width: 10%;
                  height: 100%;
                }
                // icon is camera icon
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .themes {
    .themes-box {
      .box-left {
        .description {
          #topic {
            padding: 40px 100px 0;
            font-size: 35px;
            line-height: 40px;
          }

          #text {
            padding: 30px;
          }
        }
      }

      .box-right {
        z-index: 10;
        width: calc(30% - 40px);
        height: 100vh;
        .travel {
          width: 100%;
          height: 100%;

          .title {
            text-align: center;
            color: white;
            height: 102px;
          }

          .gallery {
            height: calc(100vh - 102px);
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .list-img {
              position: relative;
              height: 25%;
              width: 50%;
              border-style: solid;
              border-color: white;
              background-color: gray;

              .image {
                position: absolute;
                bottom: 0;
                height: 20%;
                width: 100%;
                background-image: linear-gradient(
                  rgba(0, 0, 0, 0),
                  rgba(0, 0, 0, 0.6)
                );

                .icon {
                  position: absolute;
                  background-image: url("../assets/img/themes/Group 87.png");
                  background-repeat: no-repeat;
                  bottom: 30%;
                  margin-left: 5%;
                  width: 10%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>