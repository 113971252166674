<template>
  <div
    v-if="contents && contents.length > 0"
    class="in-spotlights"
    ref="carousel"
  >
    <div class="spotlighs-container">
      <h3>In the spotlights</h3>
      <carousel
        ref="carouselObj"
        :items="3"
        :margin="45"
        :nav="false"
        :dots="false"
        class="content-carousel"
      >
        <template slot="prev">
          <span class="owl-custom-prev">
            <img :src="imgs.leftArrow" />
          </span>
        </template>

        <template slot="next">
          <span class="owl-custom-next">
            <img :src="imgs.rightArrow" />
          </span>
        </template>

        <div
          v-for="content in contents"
          :key="content.id"
          class="content-item"
          :style="{ backgroundImage: `url(${content.thumbnailUrl})` }"
        >
          <div
            @click="onClickCarouselItem(content)"
            class="content-item"
            :style="{ backgroundImage: `url(${content.thumbnailUrl})` }"
          ></div>
          <!-- <div class="title-bg" :style="{ backgroundImage: `url(${content.thumbnailUrl})` }"></div> -->

          <div class="content-title">
            <router-link :to="{ name: 'map', query: { exp: content.name } }">{{
              content.title
            }}</router-link>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import axios from "axios";
import jQuery from "jquery";
import leftArrow from "@/assets/img/left-arrow.svg";
import rightArrow from "@/assets/img/right-arrow.svg";

import storyPopupManager from "@/services/StoryPopupManager";
import eventPopupManager from "@/services/EventPopupManager";

export default {
  name: "InTheSpotlights",
  components: {
    carousel,
  },
  props: {
    travelerTypes: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contents: [],
      final: true,
      imgs: {
        leftArrow,
        rightArrow,
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const url = process.env.VUE_APP_API_URI;
      const postData = {
        query: `query contents($limit: Int, $travelerTypes: [ID], $locations: [ID]) {
                contents(limit: $limit, travelerTypes: $travelerTypes, locations: $locations) {
                  hasNext results { 
                    id title name thumbnailUrl type }
                  }
                }`,
        variables: {
          limit: 9,
          travelerTypes: this.travelerTypes,
          locations: this.locations,
        },
      };

      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          const contents = res.data.data.contents;
          this.contents = contents.results;
          this.final = !contents.hasNext;
        })
        .catch(console.error);
    },
    onClickCarouselItem(content) {
      if (content.type === "experience") {
        this.$router.push({ name: "map", query: { exp: content.name } });
      } else if (content.type === "story") {
        storyPopupManager.open(content.id);
      } else if (content.type === "event") {
        eventPopupManager.open(content.id);
      }
    },
  },
};
</script>

<style lang="scss">
.in-spotlights {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .spotlighs-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: calc(8.43vw - 36px);
    width: 100%;
  }

  h3 {
    color: #2c2c2c;
    font-size: 3.38vw;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  .content-carousel {
    position: relative;

    .owl-custom-prev,
    .owl-custom-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      cursor: pointer;
    }

    .owl-custom-prev {
      left: 0;
    }

    .owl-custom-next {
      right: 0;
    }

    .owl-item {
      position: relative;
    }

    .owl-item.inactive {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.77);
        pointer-events: none;
      }
    }
  }

  .content-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    width: 20.57vw;
    height: 26.4vw;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 4;
      opacity: 1;
      transition: ease all 0.3s;
    }

    .title-bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;
      filter: blur(5px);
      width: 100%;
      height: 21.3%;
    }

    .content-title {
      color: #2c2c2c;
      background-color: rgba(255, 255, 255, 0.5);
      width: 100%;
      padding: 3.54% 7.08% 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 1.5625vw;
      font-weight: bold;
      z-index: 3;
      min-height: 21.3%;
    }

    a {
      color: #2c2c2c;
    }
  }

  .owl-item.active .content-item::after {
    opacity: 0;
  }
}
</style>
